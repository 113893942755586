function getChildrenById(nodes, item, arr) {
    for (let el of nodes) {
        if (el.id === item) {
            arr.push(el.id);
            if (el.children) {
                childNodesDeep(el.children, arr);
            }
        } else if (el.children) {
            getChildrenById(el.children, item, arr);
        }
    }
    return arr;
};
function childNodesDeep(nodes, arr) {
    nodes?.forEach((ele) => {
        arr.push(ele.id);
        if (ele.children) {
            childNodesDeep(ele.children, arr);
        }
    });
};
function getLabelById(nodes, item, arr) {
    for (let el of nodes) {
        if (el.id === item) {
            arr.push(el.label);
        } else if (el.children) {
            getLabelById(el.children, item, arr);
        }
    }
    return arr;
};

export {getChildrenById, getLabelById};
