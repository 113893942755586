<template>
    <Modal v-model="showForm" :title="modalTitle" width="640" :mask-closable="false">
        <Form ref="editForm" :model="editForm" :rules="editFormRule" label-colon :label-width="100">
            <FormItem label="客户名称" prop="customerId">
                <Select v-model="editForm.customerId" placeholder="请选择" @on-change="changeCustomer" @on-clear="clearCustomer" disabled filterable clearable>
                    <Option v-for="item in customerList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
            </FormItem>
            <FormItem label="客户来源" v-if="editForm.sourceName !== null">{{ editForm.sourceName }}</FormItem>
            <FormItem label="所在地区" prop="cityIdArray">
                <treeselect v-model="editForm.cityIdArray"
                            :multiple="true"
                            :max-height="130"
                            :options="cityOpts"
                            placeholder="请选择"
                            loadingText="加载中..."
                            noOptionsText="暂无数据"
                            noResultsText="没有匹配数据">
                </treeselect>
            </FormItem>
            <FormItem label="联系人" prop="contactId">
                <Select v-model="editForm.contactId" placeholder="请选择" @on-change="changeContact" @on-clear="clearContact" filterable clearable>
                    <Option v-for="item in contactArray" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
            </FormItem>
            <FormItem v-if="editForm.phone != null" label="联系人电话">{{ editForm.phone }}</FormItem>
            <FormItem v-if="editForm.title != null" label="联系人职位">{{ editForm.title }}</FormItem>
            <FormItem label="职位名称" prop="name">
                <Input v-model.trim="editForm.name" :maxlength="50" placeholder="请输入" disabled clearable/>
            </FormItem>
            <FormItem label="职位说明" prop="description">
                <quill-editor v-model="editForm.description" :options="editorOption"></quill-editor>
            </FormItem>
            <FormItem label="职位类型" prop="type">
                <Select v-model="editForm.type" placeholder="请选择" disabled filterable clearable>
                    <Option v-for="item in dict.position_type" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
            </FormItem>
            <FormItem label="需求金额(元)" prop="amount">
                <Input v-model="editForm.amount" placeholder="请输入"></Input>
            </FormItem>
            <FormItem label="职位级别" prop="level">
                <Select v-model="editForm.level" placeholder="请选择" filterable clearable>
                    <Option v-for="item in dict.position_level" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
            </FormItem>
            <FormItem label="访谈要点" prop="brief">
                <quill-editor v-model="editForm.brief" :options="editorOption"></quill-editor>
            </FormItem>
        </Form>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit">确定</Button>
        </div>
    </Modal>
</template>
<script>
// 调用富文本编辑器
import {quillEditor} from 'vue-quill-editor';
// 富文本编辑器外部引用样式  三种样式三选一引入即可
import 'quill/dist/quill.snow.css';
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {
    reqEditPosition,
    reqGetAllCustomer,
    reqGetContactListByCustomerId,
    reqGetCustomerPerUser,
    reqGetPositionById
} from "@/api/admin-api";
import {checkEditorContent} from "@/common/publicFuns";
import {reqDictionaryMapByCodeList} from "../../../api/system-api";
import {getLabelById} from "@/assets/js/treeFun";
import city from "@/assets/area/city";

export default {
    name: "editPosition",
    components: {
        quillEditor,
        Treeselect
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        id: '',
        custId: ''
    },
    data() {
        return {
            editorOption: { //  富文本编辑器配置
                modules:{
                    clipboard: {
                        // 粘贴版，处理粘贴时候带图片
                        matchers: [['img', this.handleCustomMatcher]],
                    },
                    toolbar:[
                        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                        ['blockquote', 'code-block'],
                        [{ 'header': 1 }, { 'header': 2 }],
                        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                        [{ 'indent': '-1'}, { 'indent': '+1' }],
                        ['clean']
                    ]
                },
                theme: 'snow',
                placeholder: '请输入'
            },
            cityOpts: city,
            loading: false,
            customerList: [],
            contactArray: [],
            showForm: this.value,
            modalTitle: null,
            dict: {},
            editForm: {
                customerId: null,
                contact: null,
                contactTitle : '',
                contactNumber : '',
                name: '',
                type: '',
                description: '',
                amount: null,
                level: '',
                brief: '',
                status: 153,
            },
            editFormRule:{
                cityIdArray: [{required: true,trigger: 'blur',
                    validator: (rule, value, callback) => {
                        if (!value || value.length === 0) {
                            return callback(new Error('请选择所在地区'));
                        }
                        if (value.length > 10) {
                            return callback(new Error('所在地区最多只能选择10个'));
                        }

                        return callback();
                    }
                }],
                customerId: [
                    {required: true, message: '请选择客户名称', trigger: 'blur'}
                ],
                description: [
                    {required: true, trigger: 'blur',
                        validator: (rule, value, callback) => {
                            if (!value || value.length === 0) {
                                return callback(new Error('请输入职位说明'));
                            }
                            if (value && !checkEditorContent(value, 2000)) {
                                return callback(new Error('职位说明不能超过2000字符'));
                            }
                            return callback();
                        }
                    }
                ],
                name: [
                    {required: true, message: '请输入职位名称', trigger: 'blur'}
                ],
                type: [
                    {required: true, message: '请选择职位类型',type: 'integer', trigger: 'blur'}
                ],
                amount: [
                    {required: true, message: "请输入金额", type: 'any', trigger: "blur"},
                    {pattern: /^(([1-9]{1}\d{0,7})|(0{1}))(\.\d{1,2})?$/, message: "请输入合法的金额数字，最多两位小数，最大金额99999999.99", trigger: "blur"}
                ],
                level: [
                    {required: true, message: '请选择级别',type: 'integer', trigger: 'blur'}
                ],
                brief: [
                    {required: true, trigger: 'blur',
                        validator: (rule, value, callback) => {
                            if (!value) {
                                return callback(new Error('请输入访谈要点'));
                            }
                            if (!checkEditorContent(value, 1000)) {
                                return callback(new Error('访谈要点不能超过1000字符'));
                            }
                            return callback();
                        }
                    }
                ],
            }
        }
    },
    methods: {
        handleSubmit() {
            this.loading = true;
            this.$refs['editForm'].validate((valid) => {
                if (!valid) {
                    this.loading = false;
                    return false;
                }
                if (this.editForm.amount === '0'){
                    this.$Message.error("金额不能为0");
                    this.loading = false;
                    return false;
                }
                if (this.editForm.cityIdArray && this.editForm.cityIdArray.length > 0) {
                    this.editForm.area = this.editForm.cityIdArray.join(',');
                    let nameArray = [];
                    this.editForm.cityIdArray.map(item => {
                        getLabelById(this.cityOpts, item, nameArray);
                    });
                    this.editForm.areaName = nameArray.join('，');
                } else {
                    this.editForm.area = null;
                    this.editForm.areaName = null;
                }

                reqEditPosition(this.editForm).then((res) => {
                    this.loading = false;
                    if (res.data.data === -1) {
                        this.$Message.error('编辑失败，该职位重复，请核实后重新添加。');
                    } else if (res.data.data === 1) {
                        this.$Message.success('编辑成功');
                        this.$emit('getPosition');
                        this.closeModal(false);
                    } else {
                        this.$Message.error('编辑失败');
                    }
                }).catch(()=>{
                    this.loading = false;
                    this.$Message.error('编辑失败');
                })
            });
        },
        // 富文本编辑器不允许输入图片
        handleCustomMatcher(node, Delta) {
            let ops = []
            Delta.ops.forEach(op => {
                if (op.insert && typeof op.insert === 'string') {// 如果粘贴了图片，这里会是一个对象，如果是字符串则可以添加
                    ops.push({
                        insert: op.insert,
                    })
                }
            })
            Delta.ops = ops
            return Delta
        },
        init() {
            let promiseDict = new Promise((resolve, reject) => {
                this.dict = {};
                let codes = ['position_type', 'position_level'];
                reqDictionaryMapByCodeList(codes).then((res) => {
                    this.dict = res.data.data;
                    resolve(true);
                }).catch(() => {
                    this.dict = {};
                })
            });
            let promiseCustomer = new Promise((resolve, reject) => {
                reqGetAllCustomer().then((res) => {
                    this.customerList = res.data;
                    resolve(true);
                }).catch(() => {
                    this.customerList = []
                })
            });

            Promise.all([promiseDict, promiseCustomer]).then(value => {
                this.editForm = {};
                //通过Id查询职位信息
                reqGetPositionById({id:this.id}).then((res) => {
                    if (res.data.data) {
                        res.data.data.cityIdArray = (res.data.data.area ? res.data.data.area.split(',') : []);
                        this.editForm = res.data.data;
                        this.changeCustomer(res.data.data.customerId)
                    }
                }).catch((err) => {
                    this.editForm = {}
                })
            }).catch(reason => {
                console.log(reason);
            })
        },
        changeCustomer(val){
            let customer = this.customerList.find(item => item.id === val);
            if (customer){
                this.editForm.sourceName = customer.sourceName
            }
            reqGetContactListByCustomerId({id:val}).then((res)=>{
                if(res.data.code === 1){
                    this.contactArray = res.data.data
                    this.changeContact(this.editForm.contactId)
                }else {
                    this.contactArray = []
                }
            }).catch(()=>{
                this.contactArray = []
            })
        },
        changeContact(val){
            if (val){
                let contact = this.contactArray.find(item => item.id === val);
                if (contact){
                    this.editForm.phone = contact.phone
                    this.editForm.title = contact.title
                }
            }
        },
        clearCustomer(){
            this.editForm.contactId = null
            this.editForm.sourceName = null
            this.editForm.phone = null
            this.editForm.title = null
        },
        clearContact(){
            this.editForm.phone = null
            this.editForm.title = null
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.$refs['editForm'].resetFields();
                this.modalTitle = '编辑职位 (ID:' + this.custId + ')';
                this.editForm.phone = null
                this.editForm.title = null
                this.editForm.sourceName = null
                this.init();
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    }
}
</script>
