<template>
    <Modal v-model="showForm" :title="modalTitle" width="960" :mask-closable="false">
        <Form class="plain-form" label-colon :label-width="90">
            <div class="custom-form">
                <div class="divider">职位信息</div>
                <div class="custom-form-section">
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="客户名称">{{ myPosition.customerName }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="客户来源">{{ myPosition.customerSourceName }}</FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="所在地区">{{ myPosition.areaName }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="联系人">{{ myPosition.contact }}</FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="联系人电话">{{ myPosition.contactPhone }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="联系人职位">{{ myPosition.contactTitle }}</FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="职位名称">{{ myPosition.name }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="类型">{{ myPosition.typeName }}</FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="级别">{{ myPosition.levelName }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="金额">{{ amount }}</FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="顾问">{{ myPosition.consultantNames }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="创建人">{{ myPosition.createByName }}</FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="24">
                            <FormItem label="创建时间">{{ myPosition.createTime }}</FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="24">
                            <FormItem class="multiline-content" label="职位说明">
                                <div v-html="myPosition.description"></div>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="24">
                            <FormItem class="multiline-content" label="访谈要点">
                                <div v-html="myPosition.brief"></div>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="状态">{{ myPosition.statusName }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="结束原因">{{ myPosition.reason }}</FormItem>
                        </Col>
                    </Row>
                </div>
                <div class="divider">交付记录</div>
                <div class="custom-form-section">
                    <Table stripe :columns="copyColumns" :data="dataList"></Table>
                </div>
            </div>
        </Form>
        <div slot="footer">
            <Button type="primary" @click="closeModal(false)">确定</Button>
        </div>
        <detail v-model="showDetailModel" :position="copyOfPosition" :deliveryId="deliveryId"></detail>
        <detail></detail>
    </Modal>
</template>
<script>
import {reqGetAvailDeliveries} from "@/api/admin-api";
import detail from './detail'

export default {
    name: "viewPosition",
    components: {detail},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        position: {}
    },
    data() {
        return {
            showForm: this.value,
            modalTitle: null,
            myPosition: {},
            copyOfPosition: {},
            amount: null,
            deliveryId: null,
            showDetailModel: false,
            dataList: [],
            copyColumns: [],
            columns: [
                {
                    title: '顾问',
                    key: 'consultantName',
                    width: 100,
                },
                {
                    title: '交付进程',
                    key: 'statusName',
                    width: 90,
                },
            ],
        }
    },
    methods: {
        getPositionDeliveries() {
            reqGetAvailDeliveries({id: this.position.id}).then((res) => {
                this.dataList = res.data.data || [];
            }).catch(() => {
                this.dataList = [];
            })
        },
        handleReviewDelivery(row, index) {
            this.copyOfPosition = JSON.parse(JSON.stringify(this.position));
            this.copyOfPosition.consultantName = row.consultantName;
            this.deliveryId = row.id;
            this.showDetailModel = true;
        },
        setColumns() {
            let myColumns = JSON.parse(JSON.stringify(this.columns));
            if (this.myPosition.typeName === '面试') {
                myColumns.push(
                    {
                        title: '推荐',
                        key: 'recommend',
                        width: 80,
                        align: 'center',
                    },
                    {
                        title: '约面',
                        key: 'booking',
                        width: 80,
                        align: 'center',
                    },
                    {
                        title: '到场',
                        key: 'present',
                        width: 80,
                        align: 'center',
                    },
                    {
                        title: '回款金额',
                        key: 'receive',
                        minWidth: 90,
                    },
                    {
                        title: '业绩金额',
                        key: 'performance',
                        minWidth: 90,
                    },
                    {
                        title: '交付人数',
                        key: 'handoverQuantity',
                        minWidth: 90,
                    },
                    {
                        title: '操作',
                        key: 'action',
                        align: 'center',
                        width: 80,
                        render: (h, params, index) => {
                            let content = [h('a', {
                                on: {
                                    'click': () => {
                                        this.handleReviewDelivery(params.row, index);
                                    }
                                }
                            }, '详情')];
                            return h('div', content);
                        }
                    }
                )
            } else if (this.myPosition.typeName === '入职') {
                myColumns.push(
                    {
                        title: '推荐',
                        key: 'recommend',
                        width: 70,
                        align: 'center',
                    },
                    {
                        title: '初试',
                        key: 'firstRound',
                        width: 70,
                        align: 'center',
                    },
                    {
                        title: '复试',
                        key: 'secondRound',
                        width: 70,
                        align: 'center',
                    },
                    {
                        title: 'offer',
                        key: 'offer',
                        width: 80,
                        align: 'center',
                    },
                    {
                        title: '入职',
                        key: 'entry',
                        width: 80,
                        align: 'center',
                    },
                    {
                        title: '回款金额',
                        key: 'receive',
                        minWidth: 90,
                    },
                    {
                        title: '业绩金额',
                        key: 'performance',
                        minWidth: 90,
                    },
                    {
                        title: '交付人数',
                        key: 'handoverQuantity',
                        minWidth: 90,
                    },
                    {
                        title: '操作',
                        key: 'action',
                        align: 'center',
                        width: 80,
                        render: (h, params, index) => {
                            let content = [h('a', {
                                on: {
                                    'click': () => {
                                        this.handleReviewDelivery(params.row, index);
                                    }
                                }
                            }, '详情')];
                            return h('div', content);
                        }
                    }
                )
            } else {
                myColumns.push(
                    {
                        title: '回款金额',
                        key: 'receive',
                        minWidth: 90,
                    },
                    {
                        title: '业绩金额',
                        key: 'performance',
                        minWidth: 90,
                    },
                    {
                        title: '交付人数',
                        key: 'handoverQuantity',
                        minWidth: 90,
                    },
                    {
                        title: '操作',
                        key: 'action',
                        align: 'center',
                        width: 80,
                        render: (h, params, index) => {
                            let content = [h('a', {
                                on: {
                                    'click': () => {
                                        this.handleReviewDelivery(params.row, index);
                                    }
                                }
                            }, '详情')];
                            return h('div', content);
                        }
                    }
                )
            }
            this.copyColumns = myColumns
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.myPosition = this.position;
                this.amount = this.myPosition.amount.toFixed(2);
                this.modalTitle = '职位详情 (ID:' + this.position.custId + ')';
                this.getPositionDeliveries()
                this.setColumns()
            } else {// 反之则关闭页面
                this.dataList = [];
                this.closeModal(val);
            }
        }
    }
}
</script>
