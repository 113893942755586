<template>
    <Modal :title="modalTitle" v-model="showForm" :width="680" :mask-closable="false">
        <Form class="plain-form" ref="viewForm" label-colon :label-width="110">
            <div class="divider">来源信息</div>
            <div class="plain-form-section">
                <Row :gutter="16">
                    <Col span="12">
                        <FormItem label="简历来源">{{ viewForm.externalId ? '同步自网报系统' : '本系统添加' }}</FormItem>
                    </Col>
                    <Col span="12">
                        <FormItem label="更新时间">{{ viewForm.updatedTime }}</FormItem>
                    </Col>
                </Row>
                <Row :gutter="16">
                    <Col span="12">
                        <FormItem label="上传人">{{ viewForm.createByName }}</FormItem>
                    </Col>
                    <Col span="12">
                        <FormItem label="上传时间">{{ viewForm.createTime }}</FormItem>
                    </Col>
                </Row>
            </div>
            <div class="divider" v-if="viewForm.applyPosition || viewForm.applyDept">应聘信息</div>
            <div class="plain-form-section">
                <Row :gutter="16">
                    <Col span="12">
                        <FormItem label="应聘职位" v-if="viewForm.applyPosition">{{ viewForm.applyPosition }}</FormItem>
                    </Col>
                    <Col span="12">
                        <FormItem label="应聘部门" v-if="viewForm.applyDept">{{ viewForm.applyDept }}</FormItem>
                    </Col>
                </Row>
            </div>
            <div class="divider">基本信息</div>
            <div class="plain-form-section">
                <template v-for="(item, index) in photoImgArray">
                    <img class="p-photo" :src="item.imgData">
                </template>
                <FormItem label="姓名" v-if="viewForm.name">{{ viewForm.name }}</FormItem>
                <FormItem label="曾用名" v-if="viewForm.formerName">{{ viewForm.formerName }}</FormItem>
                <FormItem label="性别" v-if="viewForm.gender">{{ viewForm.gender }}</FormItem>
                <FormItem label="出生年份" v-if="viewForm.birthday">{{ $moment(viewForm.birthday).format('yyyy') }}</FormItem>
                <FormItem label="籍贯" v-if="viewForm.birthplaceName">{{ viewForm.birthplaceName }}</FormItem>
                <FormItem label="民族" v-if="viewForm.nationName">{{ viewForm.nationName }}</FormItem>
                <FormItem label="身高" v-if="viewForm.height">{{ viewForm.height }}cm</FormItem>
                <FormItem label="体重" v-if="viewForm.weight">{{ viewForm.weight }}kg</FormItem>
                <FormItem label="手机号码" v-if="viewForm.phone">{{ viewForm.phone }}</FormItem>
                <FormItem label="身份证号" v-if="viewForm.idNumber">{{ viewForm.idNumber }}</FormItem>
                <FormItem label="健康状况" v-if="viewForm.healthyName">{{ viewForm.healthyName }}</FormItem>
                <FormItem label="婚姻状况" v-if="viewForm.maritalStatusName">{{ viewForm.maritalStatusName }}</FormItem>
                <FormItem label="政治面貌" v-if="viewForm.politicsName">{{ viewForm.politicsName }}</FormItem>
                <FormItem label="入党时间" v-if="viewForm.politics === 202703">{{ viewForm.partyDate }}</FormItem>
                <FormItem label="第一学历" v-if="viewForm.firstDegreeName">{{ viewForm.firstDegreeName }}</FormItem>
                <FormItem label="最高学历" v-if="viewForm.highestDegreeName">{{ viewForm.highestDegreeName }}</FormItem>
                <FormItem label="参加工作时间" v-if="viewForm.startWorkFrom">{{ viewForm.startWorkFrom }}</FormItem>
                <FormItem label="邮箱" v-if="viewForm.email">{{ viewForm.email }}</FormItem>
                <FormItem label="户口所在地" v-if="viewForm.domicilePlaceName">{{ viewForm.domicilePlaceName }}</FormItem>
                <FormItem label="家庭详细地址" v-if="viewForm.homeAddress">{{ viewForm.homeAddress }}</FormItem>
                <FormItem label="通信地址" v-if="viewForm.mailingAddress">{{ viewForm.mailingAddress }}</FormItem>
                <FormItem label="意向城市" v-if="viewForm.intentionName">{{ viewForm.intentionName }}</FormItem>
                <FormItem label="现居住地" v-if="viewForm.address">{{ viewForm.address }}</FormItem>
                <FormItem label="紧急联系人姓名" v-if="viewForm.ecn">{{ viewForm.ecn }}</FormItem>
                <FormItem label="紧急联系人电话" v-if="viewForm.ecp">{{ viewForm.ecp }}</FormItem>
                <FormItem label="目前税前年薪(万元)" v-if="viewForm.currentAnnualSalary">{{ viewForm.currentAnnualSalary }}</FormItem>
                <FormItem label="期望年薪(万元)" v-if="viewForm.expectAnnualSalary">{{ viewForm.expectAnnualSalary }}</FormItem>
                <FormItem label="到岗时间" v-if="viewForm.dutyTime">{{ viewForm.dutyTime }}</FormItem>
                <FormItem label="是否服从调配" v-if="viewForm.adjustable">{{ viewForm.adjustable }}</FormItem>
                <FormItem label="组织关系所在地" v-if="viewForm.mcl">{{ viewForm.mcl }}</FormItem>
                <FormItem label="职称及职业资格" v-if="viewForm.jobTitleName">{{ viewForm.jobTitleName }}</FormItem>
                <FormItem label="爱好和特长" v-if="viewForm.hobby"><div class="multiline-content" v-html="viewForm.hobby"></div></FormItem>
                <FormItem label="自我评价" v-if="viewForm.selfEvaluation"><div class="multiline-content" v-html="viewForm.selfEvaluation"></div></FormItem>
            </div>
            <div class="divider" v-if="show.languageShow">外语水平</div>
            <div class="plain-form-section">
                <div class="plain-form-default-section" v-for="item in viewForm.language">
                    <FormItem :label="item.language_1">{{ item.language_1label }}</FormItem>
                    <FormItem :label="item.language_2">{{ item.language_2label }}</FormItem>
                </div>
            </div>
            <div class="divider" v-if="show.educationShow">教育经历<span class="tips">注：从高中起</span></div>
            <div class="plain-form-section">
                <div class="plain-form-default-section" v-for="item in viewForm.education">
                    <FormItem :label="item.education_1">{{ (item.education_1value ? item.education_1value : '') + ' 至 ' + (item.education_8value ? item.education_8value : '') }}</FormItem>
                    <FormItem :label="item.education_2">{{ item.education_2value }}</FormItem>
                    <FormItem :label="item.education_3">{{ item.education_3value }}</FormItem>
                    <FormItem :label="item.education_4">{{ item.education_4label }}</FormItem>
                    <FormItem :label="item.education_5">{{ item.education_5label }}</FormItem>
                    <FormItem :label="item.education_6">{{ item.education_6value }}</FormItem>
                    <FormItem :label="item.education_7">{{ item.education_7value }}</FormItem>
                </div>
            </div>
            <div class="divider" v-if="show.workShow">工作经历<span class="tips">注：不含实习经历</span></div>
            <div class="plain-form-section">
                <div class="plain-form-default-section" v-for="item in viewForm.work">
                    <FormItem :label="item.work_1">{{ (item.work_1value ? item.work_1value : '') + ' 至 ' + (item.work_8value ? item.work_8value : '') }}</FormItem>
                    <FormItem :label="item.work_2">{{ item.work_2value }}</FormItem>
                    <FormItem :label="item.work_3">{{ item.work_3label }}</FormItem>
                    <FormItem :label="item.work_4">{{ item.work_4value }}</FormItem>
                    <FormItem :label="item.work_5">{{ item.work_5value }}</FormItem>
                    <FormItem :label="item.work_6">{{ item.work_6value }}</FormItem>
                    <FormItem :label="item.work_7"><div class="multiline-content" v-html="item.work_7value"></div></FormItem>
                    <FormItem :label="item.work_9"><div class="multiline-content" v-html="item.work_9value"></div></FormItem>
                </div>
            </div>
            <div class="divider" v-if="show.projectShow">项目经验</div>
            <div class="plain-form-section">
                <div class="plain-form-default-section" v-for="item in viewForm.project">
                    <FormItem :label="item.project_1">{{ item.project_1value }}</FormItem>
                    <FormItem :label="item.project_2"><div class="multiline-content" v-html="item.project_2value"></div></FormItem>
                    <FormItem :label="item.project_3"><div class="multiline-content" v-html="item.project_3value"></div></FormItem>
                    <FormItem :label="item.project_4"><div class="multiline-content" v-html="item.project_4value"></div></FormItem>
                </div>
                <FormItem v-if="viewForm.project && viewForm.project.length>0" label="重大奖惩情况"><div class="multiline-content" v-html="viewForm.reward"></div></FormItem>
            </div>
            <div class="divider" v-if="show.familyShow">家庭成员<span class="tips">注：主要成员</span></div>
            <div class="plain-form-section">
                <div class="plain-form-default-section" v-for="item in viewForm.family">
                    <FormItem :label="item.family_1">{{ item.family_1value }}</FormItem>
                    <FormItem :label="item.family_2">{{ item.family_2value ? $moment(item.family_2value).format('yyyy') : '' }}</FormItem>
                    <FormItem :label="item.family_3">{{ item.family_3label }}</FormItem>
                    <FormItem :label="item.family_4">{{ item.family_4label }}</FormItem>
                    <FormItem :label="item.family_5">{{ item.family_5value }}</FormItem>
                    <FormItem :label="item.family_6">{{ item.family_6value }}</FormItem>
                </div>
            </div>
            <div class="divider" v-if="idImgArray.length !== 0 || bareheadedImgArray.length !== 0 || degreeImgArray.length !== 0 || educationImgArray.length !== 0 || personalImgArray.length !== 0 || otherImgArray.length !== 0">资质信息</div>
            <div class="plain-form-section">
                <div class="plain-form-block" v-if="idImgArray.length !== 0">
                    <div class="title">身份证扫描件</div>
                    <div class="tips">要求：正反面身份证件扫描件</div>
                    <div class="upload-image-list" v-for="item in idImgArray">
                        <img :src="item.imgData"/>
                        <div class="upload-image-list-cover">
                            <Icon type="ios-eye" @click.native="handlePreview(item.imgData)"></Icon>
                        </div>
                    </div>
                </div>
                <div class="plain-form-block" v-if="bareheadedImgArray.length !== 0">
                    <div class="title">近期一寸免冠电子证件照片</div>
                    <div class="tips">要求：一寸蓝底或白底免冠照片，支持.jpg, .jpeg, .png格式, 5M以内</div>
                    <div class="upload-image-list" v-for="item in bareheadedImgArray">
                        <img :src="item.imgData"/>
                        <div class="upload-image-list-cover">
                            <Icon type="ios-eye" @click.native="handlePreview(item.imgData)"></Icon>
                        </div>
                    </div>
                </div>
                <div class="plain-form-block" v-if="degreeImgArray.length !== 0">
                    <div class="title">所有学历，学位证书扫描件</div>
                    <div class="tips">要求：留学人员需上传教育部中国留学服务中心出具的国（境）外学历学位认证书</div>
                    <div class="upload-image-list" v-for="item in degreeImgArray">
                        <img :src="item.imgData"/>
                        <div class="upload-image-list-cover">
                            <Icon type="ios-eye" @click.native="handlePreview(item.imgData)"></Icon>
                        </div>
                    </div>
                </div>
                <div class="plain-form-block" v-if="educationImgArray.length !== 0">
                    <div class="title">所有学历《教育部学历证书电子注册备案表》</div>
                    <div class="tips">要求：从学信网下载PDF版且在有效期内</div>
                    <div class="upload-image-list" v-for="item in educationImgArray">
                        <img :src="item.imgData"/>
                        <div class="upload-image-list-cover">
                            <Icon type="ios-eye" @click.native="handlePreview(item.imgData)"></Icon>
                        </div>
                    </div>
                </div>
                <div class="plain-form-block" v-if="personalImgArray.length !== 0">
                    <div class="title">个人相关资格证书、职称扫描件</div>
                    <div class="upload-image-list" v-for="item in personalImgArray">
                        <img :src="item.imgData"/>
                        <div class="upload-image-list-cover">
                            <Icon type="ios-eye" @click.native="handlePreview(item.imgData)"></Icon>
                        </div>
                    </div>
                </div>
                <div class="plain-form-block" v-if="otherImgArray.length !== 0">
                    <div class="title">其他可以证明符合报名要求的相关资料</div>
                    <div class="upload-image-list" v-for="item in otherImgArray">
                        <img :src="item.imgData"/>
                        <div class="upload-image-list-cover">
                            <Icon type="ios-eye" @click.native="handlePreview(item.imgData)"></Icon>
                        </div>
                    </div>
                </div>
                <Modal title="查看图片" v-model="showPreview" footer-hide :width="860">
                    <img :src="imgView" v-if="showPreview" style="width: 100%"/>
                </Modal>
            </div>
        </Form>
        <div slot="footer">
            <Button type="primary" @click="closeModal(false)">确定</Button>
        </div>
    </Modal>
</template>

<script>
import {reqGetResumeById} from "../../api/admin-api";
import {downloadFile} from "../../api/system-api";

export default {
    props: {
        value: {
            type: Boolean,
            default: false
        },
        id: ''
    },
    data() {
        return {
            show:{
                languageShow:false,
                educationShow: false,
                workShow:false,
                projectShow:false,
                familyShow:false
            },
            showPreview: false,
            showForm: false,
            modalTitle: '',
            imgView: null,
            photoImgArray: [],
            idImgArray: [],
            bareheadedImgArray: [],
            degreeImgArray: [],
            educationImgArray: [],
            personalImgArray: [],
            otherImgArray: [],
            viewForm: {
                project: [],
            }
        }
    },
    methods: {
        getResumeById() {
            reqGetResumeById({id: this.id}).then((res) => {
                let myData = res.data.data;
                if (myData.job_title_id) {
                    myData.job_title_id = myData.job_title_id.split(',');
                }
                myData.language = myData.language ? JSON.parse(myData.language) : myData.language;
                myData.education = myData.education ? JSON.parse(myData.education) : myData.education;
                myData.work = myData.work ? JSON.parse(myData.work) : myData.work;
                myData.project = myData.project ? JSON.parse(myData.project) : myData.project;
                myData.family = myData.family ? JSON.parse(myData.family) : myData.family;
                let photo = myData.photo;
                if (photo) {
                    downloadFile({fileName: photo, type: 'private'}).then((res2) => {
                        let myData = window.URL.createObjectURL(new Blob([res2.data]));
                        this.photoImgArray.push({imgName: photo, imgData: myData});
                    });
                }
                let cert = myData.certification ? JSON.parse(myData.certification) : myData.certification;
                myData.certification = cert;
                cert && Object.keys(cert) && Object.keys(cert).forEach(key => {
                    let key_values = cert[key] || [];
                    let arr = key.replace('Imgs', 'ImgArray');
                    key_values.forEach(val => {
                        downloadFile({fileName: val, type: 'private'}).then((res2) => {
                            let myData = window.URL.createObjectURL(new Blob([res2.data]));
                            this[arr]?.push({imgName: val, imgData: myData});
                        });
                    })
                });
                if (myData.originalFileName) {
                    let idx = myData.originalFileName.lastIndexOf('/');
                    idx = (idx >= 0 ? idx : myData.originalFileName.lastIndexOf('\\'));
                    if (idx >= 0) {
                        myData.originalFileName = myData.originalFileName.substr(idx + 1);
                    }
                }
                this.viewForm = myData;
                this.show.languageShow = (this.viewForm.language && this.viewForm.language.length > 0);
                this.show.educationShow = (this.viewForm.education && this.viewForm.education.length > 0);
                this.show.workShow = (this.viewForm.work && this.viewForm.work.length > 0);
                this.show.projectShow = (this.viewForm.project && this.viewForm.project.length > 0);
                this.show.familyShow = (this.viewForm.family && this.viewForm.family.length > 0);
            }).catch((err) => {
                console.log('Error: ', err);
                this.viewForm = {};
            })
        },
        handlePreview(imgData) {
            this.imgView = imgData;
            this.showPreview = true;
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            if (val) {
                this.modalTitle = '简历详情 (ID: ' + this.id + ')';
                this.getResumeById();
            } else {// 反之则关闭页面
                this.photoImgArray = [];
                this.idImgArray = [];
                this.bareheadedImgArray = [];
                this.degreeImgArray = [];
                this.educationImgArray = [];
                this.personalImgArray = [];
                this.otherImgArray = [];
                this.closeModal(val);
            }
        }
    },
    mounted() {
    }
}
</script>
